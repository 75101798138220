import {useEffect, useState} from "react";
import axios from "axios";
import {customAxios} from "../../axios";
import {useHistory} from "react-router-dom";
import {RelatedLoader} from "../../components/Loader";
import {CreateFlow} from "./modals/CreateFlow";

export const FlowList = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const history = useHistory()
    const [modalOpen, setModalOpen] = useState(false)
    
    const getAllFlows = async () => {
        setLoading(true)
      try {
          const { data, status } = await customAxios.get(`/ajax/flow`)
          if (status === 200) {
              setList(data.items)
          }
      } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllFlows().then()
    }, [])

    const deleteFlow = async (id) => {
        if (!window.confirm('Ви впевнені?')) {
            return
        }

        setLoading(true)
          try {
              const { data, status } = await customAxios.delete(`/ajax/flow/${id}`)
              if (status === 204) {
                  await getAllFlows()
              }
          } catch (e) {}
        setLoading(false)
    }


    return (
        <div style={{ position: "relative", width: '100%' }}>
            {loading && <RelatedLoader />}
            <button onClick={() => setModalOpen(true)} className={'btn'} style={{ margin: '20px 0 0 20px', background: '#5d8c51', marginBottom: '-10px' }}>Створити воронку</button>
            <table className={'custom-table'}>
                <tr>
                    <th width={100}>ID</th>
                    <th>Name</th>
                    <th width={50} />
                    <th width={50} />
                </tr>
                {
                    (list || []).map(el => (
                        <tr>
                            <td>{el.id}</td>
                            <td>{el.name}</td>
                            <td onClick={() => history.push(`/flow/${el.id}`)} className={'icon-grey'}>&#9998;</td>
                            <td onClick={() => deleteFlow(el.id)} className={'icon-red'}>&#10005;</td>
                        </tr>
                    ))
                }
            </table>
            {modalOpen && <CreateFlow
                update={getAllFlows}
                close={() => setModalOpen(false)}
            />}
        </div>
    )
}