import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Modal} from "../../../components/Modal";
import {Input} from "../../../components/Input";
import {Select} from "../../../components/Select";
import {Checkbox} from "../../../components/Checkbox";

export const SmartDelayModal = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        delay: 0,
        delay_type: 's',
        days: [0,1,2,3,4,5,6],
        period_start: '00:00',
        period_end: '23:59',
        test_mode: false
    })

    const getData = () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValues({
                ...meta_data,
                days: meta_data.days.split(',').map(el => Number(el))
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [client])

    const createAction = async () => {
        setLoading(true)
        try {
            const send_data = {
                type: 'delay',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({
                    ...values,
                    days: values.days.sort().join(',')
                }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal close={close} title={'Відправити повідомлення'} loading={loading}>
            <div className={'send_message_block'}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '70%' }}>
                        <Input
                            label={'Затримка'}
                            otherProps={{
                                type: 'number',
                                value: values.delay,
                                onChange: e => setValues({ ...values, delay: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ width: '30%' }}>
                        <Select
                            hideNull
                            label={'Затримка'}
                            options={[
                                { id: 's', name: 'Секунд' },
                                { id: 'm', name: 'Хвилин' },
                                { id: 'h', name: 'Годин' },
                                { id: 'd', name: 'Днів' },
                            ]}
                            otherProps={{
                                value: values.delay_type,
                                onChange: e => setValues({ ...values, delay_type: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>
                        <Input
                            label={'З якого часу'}
                            otherProps={{
                                type: 'time',
                                value: values.period_start,
                                onChange: e => setValues({ ...values, period_start: e.target.value })
                            }}
                        />
                    </div>
                    <div style={{ width: '50%' }}>
                        <Input
                            label={'По який час'}
                            otherProps={{
                                type: 'time',
                                value: values.period_end,
                                onChange: e => setValues({ ...values, period_end: e.target.value })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Checkbox
                        className={'mt5'}
                        label={'Понеділок'}
                        checked={values.days.includes(1)}
                        onChange={() =>
                            values.days.includes(1) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 1) }) :
                                setValues({ ...values, days: [...values.days, 1] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'Вівторок'}
                        checked={values.days.includes(2)}
                        onChange={() =>
                            values.days.includes(2) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 2) }) :
                                setValues({ ...values, days: [...values.days, 2] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'Середа'}
                        checked={values.days.includes(3)}
                        onChange={() =>
                            values.days.includes(3) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 3) }) :
                                setValues({ ...values, days: [...values.days, 3] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'Четверг'}
                        checked={values.days.includes(4)}
                        onChange={() =>
                            values.days.includes(4) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 4) }) :
                                setValues({ ...values, days: [...values.days, 4] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'П`ятниця'}
                        checked={values.days.includes(5)}
                        onChange={() =>
                            values.days.includes(5) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 5) }) :
                                setValues({ ...values, days: [...values.days, 5] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'Субота'}
                        checked={values.days.includes(6)}
                        onChange={() =>
                            values.days.includes(6) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 6) }) :
                                setValues({ ...values, days: [...values.days, 6] })}
                    />
                    <Checkbox
                        className={'mt5'}
                        label={'Неділя'}
                        checked={values.days.includes(0)}
                        onChange={() =>
                            values.days.includes(0) ?
                                setValues({ ...values, days: values.days.filter(el => el !== 0) }) :
                                setValues({ ...values, days: [...values.days, 0] })}
                    />
                </div>
                <button onClick={createAction} className={'btn w100'}>Зберегти</button>
                <Checkbox
                    className={'mt5'}
                    label={'Відправити запланований час (тестовий режим)'}
                    checked={values.test_mode}
                    onChange={() => setValues({ ...values, test_mode: !values.test_mode })}
                />
            </div>
        </Modal>
    )
}