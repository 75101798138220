import {Modal} from "../../../components/Modal";
import {Input} from "../../../components/Input";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Select} from "../../../components/Select";

export const AddButtonModal = ({ close, flow_id, client, updateFlow, actionModal }) => {
    const [values, setValues] = useState({
        type: 'default',
        name: 'Кнопка',
        link: '',
    })
    const [loading, setLoading] = useState(false)

    console.log(client)

    const initialData = async () => {
        setLoading(true)
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValues(meta_data.value)
        }
        setLoading(false)
    }

    useEffect(() => {
        initialData().then()
    }, [])

    const createAction = async () => {
        setLoading(true)
        try {
            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, values) :
                await customAxios.post(`ajax/action/button`, {
                    ...values,
                    action_id: client
                })

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={'Додати кнопку'} close={close} loading={loading}>
            <div>
                <Select
                    label={'Тип кнопки'} hideNull
                    options={[{ id: 'default', name: 'Звичайна' }, { id: 'link', name: 'Посилання' }]}
                    otherProps={{
                        value: values.type,
                        onChange: e => setValues({ ...values, type: e.target.value })
                    }}
                />
                <Input
                    label={'Назва'}
                    otherProps={{
                        value: values.name,
                        onChange: e => setValues({ ...values, name: e.target.value })
                    }}
                />
                {values.type === 'link' && <Input
                    label={'Посилання у форматі http(s)'}
                    otherProps={{
                        value: values.link,
                        onChange: e => setValues({...values, link: e.target.value})
                    }}
                />}
                <button onClick={createAction} disabled={values.name.trim().length === 0} className={'btn w100'}>Створити</button>
            </div>
        </Modal>
    )
}