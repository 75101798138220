import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {customAxios} from "../../axios";
import {RelatedLoader} from "../../components/Loader";
import {Modal} from "../../components/Modal";
import {Select} from "../../components/Select";
import {Input} from "../../components/Input";
import moment from "moment";

export const SubscriberList = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const history = useHistory()
    const [modalOpen, setModalOpen] = useState(true)
    const location = useLocation()
    const urlSearchParams = new URLSearchParams(location.search);
    const query = Object.fromEntries(urlSearchParams.entries());
    const [count, setCount] = useState(0)

    const getAllSubscribers = async () => {
        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`/ajax/subscriber?page=${query?.page || 1}`)
            if (status === 200) {
                setList(data.items)
                setCount(data.total_count)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllSubscribers().then()
    }, [query.page])

    const deleteSubscriber = async (id) => {
        if (!window.confirm('Видалити підписника')) return

        setLoading(true)
        try {
            const { data, status } = await customAxios.delete(`/ajax/subscriber/${id}`)
            if (status === 204) {
                await getAllSubscribers()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div style={{ position: "relative", width: '100%' }}>
            {loading && <RelatedLoader />}
            <table className={'custom-table'}>
                <tr>
                    <th>Ім'я</th>
                    <th>Телефон</th>
                    <th>Дата створення</th>
                    <th>Статус</th>
                    <th width={50} />
                    <th width={50} />
                </tr>
                {
                    (list || []).map(el => (
                        <tr key={el.id}>
                            <td>{el.first_name}</td>
                            <td>{el.phone}</td>
                            <td>{moment(el.created_at).format('DD-MM-YYYY HH:mm')}</td>
                            <td>{el.status ? 'Активний' : 'Неактивний'}</td>
                            <td onClick={() => history.push(`/subscriber/${el.id}`)} className={'icon-grey'}>&#9998;</td>
                            <td className={'icon-red'}><a onClick={() => deleteSubscriber(el.id)}>&#10005;</a></td>
                        </tr>
                    ))
                }
            </table>
            <div className={'mt10'}>
                <Pagination
                    currentPage={+query?.page || 1}
                    pagesAmount={Math.ceil(Number(count) / 20)}
                    setCurrentPage={(el) =>
                        history.push({
                            search: `?page=${el}`,
                        })
                    }
                />
            </div>
        </div>
    )
}

const generatePaginationItems = (currentPage, pagesAmount, setCurrentPage) => {
    const PaginationItem = ({ number, isActive }) => <div className={`pagination__item ${isActive ? 'pagination__item--active' : ''}`} onClick={() => setCurrentPage(number)}>{number}</div>
    const Dots = () => <div className="pagination__item-dots">...</div>

    return (
        <>
            {!(currentPage < 3) ? <PaginationItem number={1} /> : null}
            {
                currentPage < 5
                    ? currentPage > 3
                        ? <PaginationItem number={currentPage - 2} />
                        : null
                    : <Dots />
            }

            {!(currentPage < 2) ? <PaginationItem number={currentPage - 1} /> : null}
            <PaginationItem isActive number={currentPage} />
            {!(currentPage > (pagesAmount - 1)) ? <PaginationItem number={currentPage + 1} /> : null}

            {
                (currentPage > (pagesAmount - 4))
                    ? (currentPage > (pagesAmount - 3))
                        ? null
                        : <PaginationItem number={currentPage + 2} />
                    : <Dots />
            }
            {!(currentPage > pagesAmount - 2) ? <PaginationItem number={pagesAmount} /> : null}
        </>
    )
}

export const Pagination = ({ currentPage, pagesAmount, setCurrentPage }) => (
    <div className="pagination">
        <img style={{ maxWidth: 20 }} className="pagination__arrow-left" src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png" onClick={() => (currentPage > 1 ? setCurrentPage(currentPage - 1) : null)} />
        <div className="pagination__container">
            {generatePaginationItems(currentPage, pagesAmount, setCurrentPage)}
        </div>
        <img style={{ maxWidth: 20 }} className="pagination__arrow-right" src={"https://cdn2.iconfinder.com/data/icons/font-awesome/1792/angle-left-512.png"} onClick={() => (currentPage < pagesAmount ? setCurrentPage(currentPage + 1) : null)} />
    </div>
)