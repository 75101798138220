import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {customAxios} from "../../axios";
import {RelatedLoader} from "../../components/Loader";
import moment from "moment";
import {Modal} from "../../components/Modal";
import {Input} from "../../components/Input";
import {Select} from "../../components/Select";

export const BotList = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)

    const getAllBots = async () => {
        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`/ajax/bot`)
            if (status === 200) {
                setList(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllBots().then()
    }, [])

    const deleteSubscriber = async (id) => {
        if (!window.confirm('Видалити бота та його підписників?')) return

        setLoading(true)
        try {
            const { data, status } = await customAxios.delete(`/ajax/bot/${id}`)
            if (status === 204) {
                await getAllBots()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <div style={{ position: "relative", width: '100%' }}>
            <button onClick={() => setModalOpen(true)} className={'btn'} style={{ margin: '20px 0 0 20px', background: '#5d8c51', marginBottom: '-10px' }}>
                Додати бота
            </button>
            {loading && <RelatedLoader />}
            <table className={'custom-table'}>
                <tr>
                    <th style={{ width: '100px' }}>ID</th>
                    <th>Назва</th>
                    <th>bot name</th>
                    <th>username</th>
                    <th style={{ width: '300px' }}>Дата створення</th>
                    <th>Посилання</th>
                    <th width={50} />
                </tr>
                {
                    (list || []).map(el => (
                        <tr key={el.id}>
                            <td>{el.id}</td>
                            <td>{el.name}</td>
                            <td>{el.first_name}</td>
                            <td>{el.username}</td>
                            <td>{moment(el.created_at).format('DD-MM-YYYY HH:mm')}</td>
                            <td><a target={'_blank'} href={`https://t.me/${el.username}`}>Перейти</a></td>
                            <td className={'icon-red'}><a onClick={() => deleteSubscriber(el.id)}>&#10005;</a></td>
                        </tr>
                    ))
                }
            </table>
            {modalOpen && <NewBot close={() => setModalOpen(false)} update={getAllBots} />}
        </div>
    )
}

const NewBot = ({ close, update }) => {
    const [value, setValue] = useState('')
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    const createNewChat = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/ajax/bot`, {
                token: value, name
            })
            if (status === 201) {
                update()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={'Додати бота'} close={close} loading={loading}>
            <div>
                <Input
                    label={'Назва бота'}
                    otherProps={{
                        value: name,
                        onChange: e => setName(e.target.value)
                    }}
                />
                <Input
                    label={'Токен'}
                    otherProps={{
                        value: value,
                        onChange: e => setValue(e.target.value)
                    }}
                />
                <button onClick={createNewChat} disabled={!value.trim() || !name.trim()} className="btn w100">Додати</button>
            </div>
        </Modal>
    )
}