function generateRandomNumber(length) {
    const randomNumber = Array.from({ length }, () => Math.floor(Math.random() * 10));
    return randomNumber.join("");
}

export const TextArea = ({ label, valid = true, otherProps, className }) => {
    const input_id = generateRandomNumber(10)
    const validClass = !valid ? 'input-unvalid' : ''

    return (
        <div className={`${className} input ${validClass}`}>
            {label && <label htmlFor={input_id}>{label}</label>}
            <textarea
                id={input_id}
                {...otherProps}
            />
            {!valid && <span>Помилка валідації</span>}
        </div>
    )
}