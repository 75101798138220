import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Modal} from "../../../components/Modal";
import EmojiPicker from "emoji-picker-react";
import {TextArea} from "../../../components/TextArea";
import {Input} from "../../../components/Input";

export const ContactRequestModal = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState('Поділіться, будь-ласка, своїм контактом')
    const [requestButton, setRequestButton] = useState('Відправити контакт')
    const [cancelButton, setCancelButton] = useState('Відмінити')

    const getData = () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValue(meta_data.message)
            setRequestButton(meta_data.request_button)
            setCancelButton(meta_data.cancel_button)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [client])

    const createAction = async () => {
        setLoading(true)
        try {
            const send_data = {
                type: 'get_contact',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({
                    message: value,
                    request_button: requestButton,
                    cancel_button: cancelButton
                }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal close={close} title={'Запит контакту'} loading={loading}>
            <div className={'send_message_block'}>
                <div className={'send_message_block__emoji'}>
                    <div style={{ position: 'relative' }}>
                        <b>&#9786;</b>
                        <div className={'send_message_block__picker'}>
                            <EmojiPicker
                                onEmojiClick={(e) => setValue(prevState => prevState + e.emoji)}
                            />
                        </div>
                    </div>
                </div>
                <TextArea
                    label={'Повідомлення'}
                    otherProps={{
                        rows: 6,
                        value,
                        onChange: e => setValue(e.target.value)
                    }}
                />
                <Input
                    label={'Кнопка запиту'}
                    otherProps={{
                        value: requestButton,
                        onChange: e => setRequestButton(e.target.value)
                    }}
                />
                <button disabled={value.trim().length === 0 || requestButton.trim().length === 0 || cancelButton.trim().length === 0} onClick={createAction} className={'btn w100'}>Зберегти</button>
            </div>
        </Modal>
    )
}