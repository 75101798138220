import {useEffect, useState} from "react";
import {customAxios} from "../axios";

export const ReportPage = () => {
    const [activationFlow, setActivationsFlow] = useState()
    const [subscribeTable, setSubscribeTable] = useState([])

    const getData = async () => {
        const { status, data } = await customAxios.get(`/ajax/report`)
        if (status === 200) {
            setActivationsFlow(data.flows || [])
            setSubscribeTable(data.bots || [])
        }
    }

    useEffect(() => {
        getData().then()
    }, [])

    return (
        <div>
            <h2 style={{ fontSize: '16px', fontWeight: 900, margin: '25px 0 0 25px', color: '#2b2b2b' }}>Кількість активацій по воронкам</h2>
            <table className="custom-table" style={{ marginTop: '5px' }}>
                <tr>
                    <th>Воронка</th>
                    <th>К-ть активацій</th>
                </tr>
                {
                    (activationFlow || []).map(el => (
                        <tr>
                            <td>{el.name}</td>
                            <td width={150}>{el.count}</td>
                        </tr>
                    ))
                }
            </table>


            <h2 style={{ fontSize: '16px', fontWeight: 900, margin: '25px 0 0 25px', color: '#2b2b2b' }}>Підписки та відписки по ботам</h2>
            <table className="custom-table" style={{ marginTop: '5px' }}>
                <tr>
                    <th>Бот</th>
                    <th>Підписок</th>
                    <th>Відписок</th>
                </tr>
                {
                    (subscribeTable || []).map(el => (
                        <tr>
                            <td>{el.name}</td>
                            <td width={150}>{el.subscribe}</td>
                            <td width={150}>{el['unsubscribe']}</td>
                        </tr>
                    ))
                }
            </table>
        </div>
    )
}