import {Modal} from "../../../components/Modal";
import {Input} from "../../../components/Input";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Select} from "../../../components/Select";

export const AddTagModal = ({ close, flow_id, client, updateFlow, actionModal }) => {
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [tags, setTags] = useState([])

    const getAllTags = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/ajax/tag`)
            if (status === 200) {
                setTags(data.items)
                if (client?.data) {
                    const meta_data = JSON.parse(client.data.meta_data)
                    setValue(meta_data.value)
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllTags().then()
    }, [])

    const createAction = async () => {
        setLoading(true)
        try {
            const send_data = {
                type: actionModal,
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({
                    value,
                    name: tags.find(el => +el.id === +value)?.name
                }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={actionModal === 'add_tag' ? 'Додати тег' : 'Видалити тег'} close={close} loading={loading}>
            <div>
                <Select
                    label={'Назва воронки'}
                    options={tags}
                    otherProps={{
                        value: value,
                        onChange: e => setValue(e.target.value)
                    }}
                />
                <button onClick={createAction} disabled={value.trim().length === 0} className={'btn w100'}>Створити</button>
            </div>
        </Modal>
    )
}