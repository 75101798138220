import {Input} from "../../components/Input";
import {useState} from "react";
import {RelatedLoader} from "../../components/Loader";
import axios from "axios";

export const Login = () => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        login: '',
        password: ''
    })

    const auth = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`https://botapi.at.university/ajax/login`, {
                login: values.login,
                password: values.password
            })
            if (status === 200) {
                localStorage.setItem('authorization', data.token)
                window.location.reload()
            }
        } catch (e) {
            if (e?.response?.data?.message) {
                alert(e?.response?.data?.message)
            }
        }
        setLoading(false)
    }

    return <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        {loading && <RelatedLoader />}
        <div style={{ width: '450px' }}>
            <Input
                label={'Логін'}
                otherProps={{
                    value: values.login,
                    onChange: e => setValues({ ...values, login: e.target.value })
                }}
            />
            <Input
                className={'mt10'}
                label={'Пароль'}
                otherProps={{
                    value: values.password,
                    onChange: e => setValues({ ...values, password: e.target.value }),
                    type: 'password'
                }}
            />
            <button onClick={auth} className={'w100 btn mt10'}>Увійти</button>
        </div>
    </div>
}