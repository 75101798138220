import {Modal} from "../../../components/Modal";
import {Input} from "../../../components/Input";
import {useState} from "react";
import {customAxios} from "../../../axios";

export const CreateFlow = ({ close, update }) => {
    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)

    const createFlow = async () => {
        setLoading(true)
        const { status } = await customAxios.post(`/ajax/flow`, { name: text })
        if (status === 201) {
            update()
            close()
        }
        setLoading(false)
    }

    return (
        <Modal title={'Створити воронку'} close={close} loading={loading}>
            <div>
                <Input
                    label={'Назва воронки'}
                    otherProps={{
                        value: text,
                        onChange: e => setText(e.target.value)
                    }}
                />
                <button onClick={createFlow} disabled={text.trim().length === 0} className={'btn w100'}>Створити воронку</button>
            </div>
        </Modal>
    )
}