import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {customAxios} from "../../axios";
import {RelatedLoader} from "../../components/Loader";
import {Modal} from "../../components/Modal";
import {Select} from "../../components/Select";
import {Input} from "../../components/Input";

export const TagList = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const history = useHistory()
    const [modalOpen, setModalOpen] = useState(false)

    const getAllTags = async () => {
        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`/ajax/tag`)
            if (status === 200) {
                setList(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllTags().then()
    }, [])

    const deleteTag = async (id) => {
        if (!window.confirm('Ви впевнені?')) { return }

        setLoading(true)
        try {
            const { data, status } = await customAxios.delete(`/ajax/tag/${id}`)
            if (status === 204) {
                await getAllTags()
            }
        } catch (e) {}
        setLoading(false)

    }

    return (
        <div style={{ position: "relative", width: '100%' }}>
            {loading && <RelatedLoader />}
            <button onClick={() => setModalOpen(true)} className={'btn'} style={{ margin: '20px 0 0 20px', background: '#5d8c51', marginBottom: '-10px' }}>
                Створити тег
            </button>
            <table className={'custom-table'}>
                <tr>
                    <th>Name</th>
                    <th width={50} />
                </tr>
                {
                    (list || []).map(el => (
                        <tr key={el.id}>
                            <td>{el.name}</td>
                            <td className={'icon-red'}><a onClick={() => deleteTag(el.id)}>&#10005;</a></td>
                        </tr>
                    ))
                }
            </table>
            {
                modalOpen && <CreateTag
                    close={() => setModalOpen(false)}
                    update={getAllTags}
                />
            }
        </div>
    )
}

const CreateTag = ({ close, update }) => {
    const [value, setValue] = useState('')
    const [name, setName] = useState('')
    const [loading, setLoading] = useState(false)

    const createNewChat = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/ajax/tag`, {
                name: name
            })
            if (status === 201) {
                update()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal title={'Створити тег'} close={close} loading={loading}>
            <div>
                <Input
                    label={'Назва тегу'}
                    otherProps={{
                        value: name,
                        onChange: e => setName(e.target.value)
                    }}
                />
                <button onClick={createNewChat} disabled={!name.trim()} className="btn w100">Створити тег</button>
            </div>
        </Modal>
    )
}