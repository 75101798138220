import {Modal} from "../../../components/Modal";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import EmojiPicker from "emoji-picker-react";
import {TextArea} from "../../../components/TextArea";

export const SendPictureModal = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [imageSrc, setImageSrc] = useState('');
    const [file, setFile] = useState(null)
    const [value, setValue] = useState('')

    const getData = () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            // setValue(meta_data.message)
            setValue(meta_data?.value)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [client])

    const createAction = async (file) => {
        setLoading(true)
        try {
            const send_data = {
                type: 'send_picture',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({ file: file, value }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    const sendPictureToServer = async () => {
        const formData = new FormData()
        formData.append('image', file, file.name)

        try {
            const { status, data } = await customAxios.post('ajax/upload-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (data.success) {
                await createAction(data.file)
            }
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error);
            alert('Ошибка при загрузке файла');
        }
    }

    const onPictureChoose = (event) => {
        if (event?.target?.files[0]) {
            const file = event.target.files[0];

            setFile(event.target.files[0])

            if (!file.type.startsWith('image/')) {
                alert('Выберите изображение.');
                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result);
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <Modal close={close} title={'Відправити картинку'} loading={loading}>
            <div>
                <div className={'picture-modal'}>
                    <div className={'send_message_block'} style={{ marginBottom: '5px' }}>
                        <div className={'send_message_block__emoji'}>
                            <div style={{ position: 'relative' }}>
                                <b>&#9786;</b>
                                <div className={'send_message_block__picker'}>
                                    <EmojiPicker
                                        onEmojiClick={(e) => setValue(prevState => prevState + e.emoji)}
                                    />
                                </div>
                            </div>
                        </div>
                        <TextArea
                            label={'Повідомлення'}
                            otherProps={{
                                rows: 10,
                                value,
                                onChange: e => setValue(e.target.value)
                            }}
                        />
                    </div>
                    {file && <img style={{ maxWidth: '100%' }} src={imageSrc} alt=""/>}
                    {!file && <label className={'picture-modal__file'} htmlFor="file_label">
                        <div className="picture-modal__text">
                            Завантажити зображення
                        </div>
                        <input value={file} accept="image/*" onChange={onPictureChoose} type="file" id={'file_label'}/>
                    </label>}
                </div>

                {file && <button style={{background: '#5d8c51'}} onClick={() => {
                    setFile(null)
                    setImageSrc(null)
                }}
                         className={'btn w100'}>Очистити</button>}
                <button disabled={!file && !value} onClick={sendPictureToServer} className={'btn w100'}>Зберегти</button>
            </div>
        </Modal>
    )
}