import {Modal} from "../../../components/Modal";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Select} from "../../../components/Select";
import {Input} from "../../../components/Input";

const arrayToObject = (array) => {
    const obj = {}
    array.map(el => {
        if (el.label.trim() && el.value.trim()) {
            obj[el.label] = el.value
        }
    })
    return obj
}

const objectToArray = (obj) => {
    const arr = []
    Object.keys(obj).map(key => {
        arr.push({ label: key, value: obj[key] })
    })
    return arr
}

export const HttpRequest = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [headers, setHeaders] = useState([])
    const [body, setBody] = useState([])
    const [values, setValues] = useState({
        url: '',
        method: 'get'
    })

    const getData = () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValues({
                ...meta_data,
            })
            setHeaders(objectToArray(meta_data.headers))
            setBody(objectToArray(meta_data.body))
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [client])

    const createAction = async () => {
        if (!values.url.trim()) {
            alert('Помилка валідації')
            return
        }

        const sendHeadersData = arrayToObject(headers)
        const sendBodyData = arrayToObject(body)

        setLoading(true)
        try {
            const send_data = {
                type: 'http_request',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({
                    ...values,
                    headers: sendHeadersData,
                    body: sendBodyData
                }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }
    return (
        <Modal close={close} title={'HTTP запит'} loading={loading}>
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '30%' }}>
                        <Select
                            label={'Метод'}
                            otherProps={{
                                value: values.method,
                                onChange: e => setValues({ ...values, method: e.target.value })
                            }}
                            options={[
                                { id: 'get', name: 'GET' },
                                { id: 'post', name: 'POST' },
                                { id: 'put', name: 'PUT' },
                                { id: 'delete', name: 'DELETE' },
                            ]}
                        />
                    </div>
                    <div style={{ width: '70%' }}>
                        <Input
                            label={'URL'}
                            otherProps={{
                                value: values.url,
                                onChange: e => setValues({ ...values, url: e.target.value })
                            }}
                        />
                    </div>
                </div>

                <div>
                    <div style={{ fontSize: '13px', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '15px', color: '#5d8c51' }}>Headers</div>
                    <hr style={{ marginTop: '3px', marginBottom: '5px' }} />
                </div>
                {
                    (headers || []).map((el, i) => (
                        <div style={{ width: '100%', display: 'flex', marginBottom: '5px' }}>
                            <div style={{ width: '30%', marginRight: '5px' }}>
                                <Input
                                    otherProps={{
                                        placeholder: 'Key',
                                        value: headers[i].label,
                                        onChange: e => {
                                            const parseHeaders = JSON.parse(JSON.stringify(headers))
                                            parseHeaders[i].label = e.target.value
                                            setHeaders(parseHeaders)
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ width: '70%' }}>
                                <Input
                                    otherProps={{
                                        placeholder: 'Value',
                                        value: headers[i].value,
                                        onChange: e => {
                                            const parseHeaders = JSON.parse(JSON.stringify(headers))
                                            parseHeaders[i].value = e.target.value
                                            setHeaders(parseHeaders)
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    ))
                }
                <button onClick={() => { setHeaders([...headers, { value: '', label: '' }]) }} className={'btn'} style={{ background: 'rgb(93, 140, 81)', padding: '9px 14px', marginTop: '0', fontSize: '12px' }}>Додати параметр</button>

                {(values.method === 'post' || values.method === 'put') && <div>
                    <div>
                        <div style={{
                            fontSize: '13px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            marginTop: '15px',
                            color: '#5d8c51'
                        }}>Body
                        </div>
                        <hr style={{marginTop: '3px', marginBottom: '3px'}}/>
                    </div>
                    {
                        (body || []).map((el, i) => (
                            <div style={{width: '100%', display: 'flex', marginBottom: '5px'}}>
                                <div style={{width: '30%', marginRight: '5px'}}>
                                    <Input
                                        otherProps={{
                                            placeholder: 'Key',
                                            value: body[i].label,
                                            onChange: e => {
                                                const parseBody = JSON.parse(JSON.stringify(body))
                                                parseBody[i].label = e.target.value
                                                setBody(parseBody)
                                            }
                                        }}
                                    />
                                </div>
                                <div style={{width: '70%'}}>
                                    <Input
                                        otherProps={{
                                            placeholder: 'Value',
                                            value: body[i].value,
                                            onChange: e => {
                                                const parseBody = JSON.parse(JSON.stringify(body))
                                                parseBody[i].value = e.target.value
                                                setBody(parseBody)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        ))
                    }
                    <button onClick={() => {
                        setBody([...body, {value: '', label: ''}])
                    }} className={'btn'} style={{
                        background: 'rgb(93, 140, 81)',
                        padding: '9px 14px',
                        marginTop: '0',
                        fontSize: '12px'
                    }}>Додати параметр
                    </button>
                </div>}

                <button onClick={createAction} className="btn w100">Зберегти</button>
            </div>
        </Modal>
    )
}