import {Modal} from "../../../components/Modal";

const arr = [
    { value: 'edit', label: 'Редагувати елемент', color: '#757575' },
    { value: 'delete', label: 'Видалити елемент', color: '#e12b0c' },
]

const other = [
    { value: 'condition', label: 'Умова', color: '#a5a5a5' },
    { value: 'send_message', label: 'Відправити повідомлення', color: '#06b70d' },
    { value: 'delay', label: 'Затримка', color: '#06b70d' },
    { value: 'send_picture', label: 'Відправити картинку', color: '#06b70d' },
    { value: 'add_tag', label: 'Додати тег', color: '#06b70d' },
    { value: 'destroy_tag', label: 'Видалити тег', color: '#06b70d' },
    { value: 'subscribe_to_funnel', label: 'Підписати на воронку', color: '#06b70d' },
    /*{ value: 'unsubscribe_funnel', label: 'Відписати від воронки', color: '#06b70d' },*/
    { value: 'http_request', label: 'Зовнішній запит', color: '#06b70d' },
    { value: 'get_contact', label: 'Запит контакту', color: '#06b70d' },
    { value: 'contact_sent', label: 'Користувач поділився контактом', color: '#06b70d' },
    { value: 'send_video_note', label: 'Відправити відео коло', color: '#06b70d' },
    { value: 'send_video', label: 'Відправити відео', color: '#06b70d' },
]

export const ChooseToDo = ({ onClick, close, client }) => {
    return (
        <Modal title={'Обрати дію'} close={close}>
            <div className={'choose_to_do'}>
                <ul>
                    {
                        (client?.edit ? arr : other).map(el => (
                            <li onClick={() => onClick(el.value)} key={el.value}><a style={{ color: el.color }}>{el.label}</a></li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    )
}