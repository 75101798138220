import {Modal} from "../../../components/Modal";
import {TextArea} from "../../../components/TextArea";
import EmojiPicker from "emoji-picker-react";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";

export const SendMessageModal = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState('')

    const getData = () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValue(meta_data.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [client])

    const createAction = async () => {
        setLoading(true)
        try {
            const send_data = {
                type: 'send_message',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({ message: value }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal close={close} title={'Відправити повідомлення'} loading={loading}>
            <div className={'send_message_block'}>
                <div className={'send_message_block__emoji'}>
                    <div style={{ position: 'relative' }}>
                        <b>&#9786;</b>
                        <div className={'send_message_block__picker'}>
                            <EmojiPicker
                                onEmojiClick={(e) => setValue(prevState => prevState + e.emoji)}
                            />
                        </div>
                    </div>
                </div>
                <TextArea
                    label={'Повідомлення'}
                    otherProps={{
                        rows: 10,
                        value,
                        onChange: e => setValue(e.target.value)
                    }}
                />

                <button disabled={value.trim().length === 0} onClick={createAction} className={'btn w100'}>Зберегти</button>
            </div>
        </Modal>
    )
}