import {useState, useEffect} from "react";
import {RelatedLoader} from "../../components/Loader";
import {CreateFlow} from "../constructor/modals/CreateFlow";
import {customAxios} from "../../axios";
import {Modal} from "../../components/Modal";
import {Select} from "../../components/Select";
import {Input} from "../../components/Input";
import {useHistory} from "react-router-dom";

export const MailingList = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const history = useHistory()

    const getAllFlows = async () => {
        setLoading(true)
        try {
            const { data, status } = await customAxios.get(`/ajax/flow?is_newsletter=true`)
            if (status === 200) {
                setList(data.items)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllFlows().then()
    }, [])

    const activate = async (id) => {
        setLoading(true)
        const {} = await customAxios.post(`/ajax/mailing-start`, {
            flow_id: id
        })
        setLoading(false)
    }

    return (
        <div style={{ position: "relative", width: '100%' }}>
            {loading && <RelatedLoader />}
            <button onClick={() => setModalOpen(true)} className={'btn'} style={{ margin: '20px 0 0 20px', background: '#5d8c51', marginBottom: '-10px' }}>Створити розсилку</button>
            <table className={'custom-table'}>
                <tr>
                    <th>Назва</th>
                    <th width={150}></th>
                    <th width={50} />
                    {/*<th width={50} />*/}
                </tr>
                {
                    (list || []).map(el => (
                        <tr>
                            <td>{el.name}</td>
                            <td><button style={{ padding: '8px 15px', fontSize: '12px', background: '#5d8c51' }} onClick={() => activate(el.id)} className={'btn'}>Запустити</button></td>
                            <td onClick={() => history.push(`/mailing/${el.id}`)} className={'icon-grey'}>&#9998;</td>
                            {/*<td className={'icon-red'}>&#10005;</td>*/}
                        </tr>
                    ))
                }
            </table>
            {modalOpen && <MailerConditionModal
                updateFlow={getAllFlows}
                close={() => setModalOpen(false)}
            />}
        </div>
    )
}

export const MailerConditionModal = ({ close, flow_id, client, updateFlow, actionModal }) => {
    const [text, setText] = useState('')
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [tags, setTags] = useState([])
    const [flows, setFlows] = useState([])
    const [conditions, setConditions] = useState([])

    const getAllTags = async () => {
        setLoading(true)
        try {
            const { statusFlow, data: dataFlow } = await customAxios.get(`/ajax/bot`)
            setFlows(dataFlow?.items || [])
            const { status, data } = await customAxios.get(`/ajax/tag`)
            if (status === 200) {
                setTags(data.items)
                if (client?.data) {
                    const meta_data = JSON.parse(client.data.meta_data)
                    setConditions(meta_data.conditions)
                    //setValue(meta_data.value)
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllTags().then()
    }, [])

    const createAction = async () => {
        const filteredConditions = conditions.filter(e => e.value)
        /*if (filteredConditions.length === 0) {
            alert('Необхідно коректно додати як мінімум одну умову')
            return
        }*/

        setLoading(true)
        const { status } = await customAxios.post(`/ajax/mailing`, {
            name: text,
            bot_id: value,
            conditions
        })
        if (status === 201) {
            updateFlow()
            close()
        }
        setLoading(false)
    }

    const setConditionData = (value, type, i) => {
        const conditionsData = JSON.parse(JSON.stringify(conditions))
        conditionsData[i][type] = value
        setConditions(conditionsData)
    }

    return (
        <Modal title={'Умова'} close={close} loading={loading}>
            <div>
                <Input
                    label={'Назва розсилки'}
                    otherProps={{
                        value: text,
                        onChange: e => setText(e.target.value)
                    }}
                />
                <Select
                    label={'Бот'}
                    options={flows}
                    otherProps={{
                        value: value,
                        onChange: e => setValue(e.target.value)
                    }}
                />
                <div style={{ marginTop: '5px' }}></div>
                {
                    (conditions || []).map((el, i) => (
                        <div style={{ display: 'flex', marginBottom: '3px' }}>
                            <div style={{ width: '24%', marginRight: '3px' }}>
                                <Select hideNull
                                        options={[{ id: 'in', name: 'Включає' }, {id: 'nin', name: 'Не включає'}]}
                                        otherProps={{
                                            value: conditions[i].operator,
                                            onChange: e => setConditionData(e.target.value, 'operator', i)
                                        }}
                                />
                            </div>
                            <div style={{ width: '24%', marginRight: '3px' }}>
                                <Input
                                    otherProps={{
                                        value: 'Тег',
                                        disabled: true
                                    }}
                                />
                            </div>
                            <div style={{ width: '45%' }}>
                                <Select
                                    options={tags}
                                    otherProps={{
                                        value: conditions[i].value,
                                        onChange: e => setConditionData(e.target.value, 'value', i)
                                    }}
                                />
                            </div>
                            <div style={{ width: '7%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <a onClick={() => setConditions(conditions.filter((element, index) => index !== i))} style={{ color: 'red', fontSize: '24px', cursor: 'pointer' }}>&#10005;</a>
                            </div>
                        </div>
                    ))
                }
                <button onClick={() => setConditions([...conditions, { key: 'tag', operator: 'in', value: '' }])}
                        className={'btn'} style={{ background: 'rgb(93, 140, 81)', padding: '9px 14px', marginTop: '0px', fontSize: '12px' }}>Додати умову</button>
                <button disabled={!value || !text} onClick={createAction} className={'btn w100'}>Створити розсилку</button>
            </div>
        </Modal>
    )
}