import {ModalLoader, RelatedLoader} from "./Loader";

export const Modal = ({ children, title, close, loading = false }) => {
    return (
        <div className="modal">
            <div className={'modal-container'}>
                {loading && <ModalLoader />}
                <div className="modal-container__heading">
                    <p>
                        {title}
                    </p>
                    <div onClick={close}>
                        <span>&#10005;</span>
                    </div>
                </div>
                <div className="modal-container__body">
                    {children}
                </div>
            </div>
            <div className="modal__bg" onClick={close} />
        </div>
    )
}