import React from 'react';

export const RelatedLoader = props => (
    <div className={'modal-loader'} style={{ position: 'absolute' }}>
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
);

export const ModalLoader = props => (
    <div className={'modal-loader'} style={{ position: 'fixed', height: '100%', width: '100%', left:0, top: 0, right: 0, bottom: 0 }}>
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
);