import {Modal} from "../../../components/Modal";
import {TextArea} from "../../../components/TextArea";
import EmojiPicker from "emoji-picker-react";
import {useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Select} from "../../../components/Select";

export const SubscribeToFlowModal = ({ close, flow_id, client, updateFlow }) => {
    const [loading, setLoading] = useState(true)
    const [flows, setFlows] = useState([])
    const [value, setValue] = useState('')

    const getData = async () => {
        if (client?.data) {
            const meta_data = JSON.parse(client.data.meta_data)
            setValue(meta_data.flow_id)
        }
        const { status, data } = await customAxios.get(`/ajax/flow`)
        if (status === 200) {
            setFlows(data.items.filter(el => +el.id !== +flow_id))
        }
        setLoading(false)
    }
    useEffect(() => {
        getData()
    }, [client])

    const createAction = async () => {
        if (!value) {
            alert('Необхідно обрати значення')
            return
        }

        setLoading(true)
        try {
            const send_data = {
                type: 'subscribe_to_funnel',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({
                    flow_id: value,
                    flow_title: flows.find(el => +el.id === +value)?.name
                }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    return (
        <Modal close={close} title={'Підписати на воронку'} loading={loading}>
            <div className={'send_message_block'}>
                <Select
                    options={flows}
                    otherProps={{
                        value: value,
                        onChange: e => setValue(e.target.value)
                    }}
                />
                <button disabled={value.trim().length === 0} onClick={createAction} className={'btn w100'}>Зберегти</button>
            </div>
        </Modal>
    )
}