import {Modal} from "../../../components/Modal";
import {Input} from "../../../components/Input";
import {useCallback, useEffect, useState} from "react";
import {customAxios} from "../../../axios";
import {Select} from "../../../components/Select";

export const ConditionModal = ({ close, flow_id, client, updateFlow, actionModal }) => {
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [tags, setTags] = useState([])
    const [conditions, setConditions] = useState([])

    const getAllTags = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/ajax/tag`)
            if (status === 200) {
                setTags(data.items)
                if (client?.data) {
                    const meta_data = JSON.parse(client.data.meta_data)
                    setConditions(meta_data.conditions)
                    //setValue(meta_data.value)
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getAllTags().then()
    }, [])

    const createAction = async () => {
        const filteredConditions = conditions.filter(e => e.value)
        if (filteredConditions.length === 0) {
            alert('Необхідно коректно додати як мінімум одну умову')
            return
        }

        setLoading(true)
        try {
            const send_data = {
                type: 'condition',
                flow_id,
                action_id: client.id,
                meta_data: JSON.stringify({ conditions }),
                coordinates_x: +client.clientX,
                coordinates_y: +client.clientY,
                sourceHandle: client.sourceHandle
            }

            const { status, data } = client?.data ?
                await customAxios.put(`ajax/action/${client?.data?.id}`, send_data) :
                await customAxios.post(`ajax/action`, send_data)

            if (status === 200 || status === 201) {
                updateFlow()
                close()
            }
        } catch (e) {}
        setLoading(false)
    }

    const setConditionData = (value, type, i) => {
        const conditionsData = JSON.parse(JSON.stringify(conditions))
        conditionsData[i][type] = value
        setConditions(conditionsData)
    }

    return (
        <Modal title={'Умова'} close={close} loading={loading}>
            <div>
                <p style={{ fontSize: '13px', color: '#a5a5a5', fontStyle: 'italic' }}>Наступна дія спрацює, якщо підписник буде відповідати всім умовам</p>
                <div>
                    <div style={{ fontSize: '13px', textTransform: 'uppercase', fontWeight: 'bold', marginTop: '15px', color: '#5d8c51' }}>Умови</div>
                    <hr style={{ marginTop: '3px', marginBottom: '5px' }} />
                </div>
                {
                    (conditions || []).map((el, i) => (
                        <div style={{ display: 'flex', marginBottom: '3px' }}>
                            <div style={{ width: '24%', marginRight: '3px' }}>
                                <Select hideNull
                                        options={[{ id: 'in', name: 'Включає' }, {id: 'nin', name: 'Не включає'}]}
                                        otherProps={{
                                            value: conditions[i].operator,
                                            onChange: e => setConditionData(e.target.value, 'operator', i)
                                        }}
                                />
                            </div>
                            <div style={{ width: '24%', marginRight: '3px' }}>
                                <Input
                                    otherProps={{
                                        value: 'Тег',
                                        disabled: true
                                    }}
                                />
                            </div>
                            <div style={{ width: '45%' }}>
                                <Select
                                    options={tags}
                                    otherProps={{
                                        value: conditions[i].value,
                                        onChange: e => setConditionData(e.target.value, 'value', i)
                                    }}
                                />
                            </div>
                            <div style={{ width: '7%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <a onClick={() => setConditions(conditions.filter((element, index) => index !== i))} style={{ color: 'red', fontSize: '24px', cursor: 'pointer' }}>&#10005;</a>
                            </div>
                        </div>
                    ))
                }
                <button onClick={() => setConditions([...conditions, { key: 'tag', operator: 'in', value: '' }])}
                        className={'btn'} style={{ background: 'rgb(93, 140, 81)', padding: '9px 14px', marginTop: '0px', fontSize: '12px' }}>Додати умову</button>
                <button onClick={createAction} className={'btn w100'}>Створити умову</button>
            </div>
        </Modal>
    )
}