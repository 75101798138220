function generateRandomNumber(length) {
    const randomNumber = Array.from({ length }, () => Math.floor(Math.random() * 10));
    return randomNumber.join("");
}

export const Select = ({ label, valid = true, otherProps, className, options = [], hideNull }) => {
    const input_id = generateRandomNumber(10)
    const validClass = !valid ? 'input-unvalid' : ''

    return (
        <div className={`${className} input ${validClass}`}>
            {label && <label htmlFor={input_id}>{label}</label>}
            <select
                id={input_id}
                {...otherProps}
            >
                {!hideNull && <option value={''}>Немає значення</option>}
                {
                    (options || []).map(el => (
                        <option key={el.id} value={el.id}>{el.name}</option>
                    ))
                }
            </select>
            {!valid && <span>Помилка валідації</span>}
        </div>
    )
}